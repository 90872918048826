<template>
    <div>
        <template v-if="acf.acf.show_slide_show == true">
            <div>
                <b-carousel
                id="carousel-fade"
                style="text-shadow: 0px 0px 2px #000"
                fade
                controls
                :interval="4000"
                img-width="1024"
                img-height="480"
                >
                <b-carousel-slide v-for="(slide, index) in acf.acf.slide_show" :key="index"
                    :img-src="slide.slide"
                ></b-carousel-slide>
                </b-carousel>
            </div>
        </template>
        <b-container>
            <h3 class="Header">
                Our Portfolio
            </h3>
            <b-row class="PortfolioTop-text">
                <b-col>
                    <p class="textcolour" v-html="acf.content.rendered"></p>
                </b-col>
            </b-row>

            <div class="PortfolioSection-download">
                <p class="textcolour">To download our portfolio please click the link below.</p>
                <a :href="acf.acf.download_file" :download="acf.acf.download_file" target="_blank">
                    <button class="downloadLink">{{acf.acf.download_text}}</button>
                </a>
            </div>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Portfolio',
  components: {
  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/pages/393"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>
<style scoped>
    ::v-deep h3.wp-block-heading {
    color: #7ebd63;
    font-weight: 100;
    font-size: 25px;
    /* margin-bottom: 30px; */
    margin-top: 30px;
    }
    .row.PortfolioTop-text {
        margin-bottom: 50px;
    }
    .PortfolioSection-download {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 100px;
    }
    button.downloadLink {
        background-color: #efd170;
        border-color: #efd170!important;
        border-radius: 0px;
        width: 163px;
        height: 31px;
        line-height: 1;
        color: #010322;
        border: 0;
    }
    button.downloadLink:hover {
        background-color: #7ebd63;
        border-color: #7ebd63!important;
        border-radius: 0px;
        width: 163px;
        height: 31px;
        line-height: 1;
        color: #010322;
        border: 0;
    }
    p.textcolour {
        color: #000322;
        font-size: 14px;
        text-align: left;
    }
    h3.Header {
        color: #7ebd63;
        text-transform: uppercase;
        text-align: left;
        font-size: 30px;
        padding-bottom: 15px;
        font-weight: 100;
        margin-bottom: 40px;
        margin-top: 100px;
    }
    .portfolioImage {
        width: 100%;
    }
    .colRepeater {
        margin-bottom: 30px;
    }
    ::v-deep .carousel-inner {
        /* height: 553px; */
    }
</style>
