<template>
  <div id="Independent Skills">
    <div>
      <template v-if="$route.name == 'photo-real-visualisation-and-rendering' || $route.name == '3d-walking' || $route.name == 'virtual-reality' || $route.name == '3d-floor-plans' || $route.name == 'water-colours' || $route.name == 'models'">
        <template v-if="acf.acf.show_slide_show == true">
          <div>
            <b-carousel
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              fade
              controls
              :interval="4000"
              img-width="1024"
              img-height="480"
            >
              <b-carousel-slide v-for="(slide, index) in acf.acf.slide_show" :key="index"
                :img-src="slide.slide"
              ></b-carousel-slide>
            </b-carousel>
          </div>
        </template>
        <template v-else-if="acf.acf.show_video == true">
            <div class="pageWrap">
              <div class="video-banner">
                <div class="vimeo-wrapper wow fadeIn" data-wow-duration="1.2s" style="visibility: visible; animation-duration: 1.2s; animation-name: fadeIn;">
                  <iframe src="https://player.vimeo.com/video/821260661?muted=1&amp;loop=1&amp;autoplay=1&amp;controls=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                </div>
              </div>
            </div>
        </template>
      </template>
      <div class="independent_Skills_Section container">

        <h3 class="Header">{{acf.title.rendered}}</h3>
        <div class="" v-for="(skillBlock, index) in acf.acf.skills_block" :key="index">
          <h4 class="sub_Header">{{skillBlock.sub_header}}</h4>
          <p class="desctiption" v-html="skillBlock.description"></p>
          <template v-if="skillBlock.image_content_one == true">
            <div class="outerSkills_image">
              <template v-if="$route.name == 'virtual-reality'">
                <div class="playButton">
                  <b-button variant="danger" class="playBtnVR" v-b-modal.modal-centerone>Play VR</b-button>
                  <b-img class="images mainImage" :src="skillBlock.image_one"></b-img>
                </div>
                <div>
                  <b-modal id="modal-centerone" centered title="Virtual Reality" size="xl">
                    <iframe
                      class="iframemovement"
                      width="100%"
                      height="800px" 
                      style="border: none!important;"
                      src="https://wp-ocssport.ocs-sport.com/wp-content/themes/wp-bootstrap-starter/coopershill-v3/index.htm"
                    >
                    </iframe>
                  </b-modal>
                </div>
              </template>
              <template v-else>
                <b-img class="images mainImage" :src="skillBlock.image_one"></b-img>     
              </template>     
            </div>
          </template>
          <template v-else-if="skillBlock.video_content_one == true">
            <template v-if="isMobile(true)">
              <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe :src="skillBlock.mobile_test_link" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="OCS Studios - Walkthrough 1"></iframe>
              </div>
            </template>
            <template v-else>
              <video class='vid' muted autoplay='1' loop='0'>
                <source :src="skillBlock.video_one">
              </video>
            </template>
          </template>
          <b-row class="bottomSet">
            <template v-if="skillBlock.image_content_three == false && skillBlock.video_content_three == false">
              <b-col cols lg="12" sm="12">
                <template v-if="skillBlock.image_content_two == true">
                  <div class="outerSkills_image" style="height: 100%;">
                    <template v-if="$route.name == 'virtual-reality'">
                    <div class="playButton">
                      <b-button variant="danger" class="playBtnVR" v-b-modal.modal-centertwo>Play VR</b-button>
                      <b-img class="images mainImage" :src="skillBlock.image_two" v-b-modal.modal-centertwo></b-img>
                    </div>
                      <div>
                        <b-modal id="modal-centertwo" centered title="Virtual Reality" size="xl">
                          <iframe
                            class="iframemovement"
                            width="100%"
                            height="800px" 
                            style="border: none!important;"
                            src="https://wp-ocssport.ocs-sport.com/wp-content/themes/wp-bootstrap-starter/hapstead/mountpark/c3/index.htm"
                          >
                          </iframe>
                        </b-modal>
                      </div>
                    </template>
                    <template v-else>
                      <b-img class="images mainImage" :src="skillBlock.image_two"></b-img>       
                    </template> 
                  </div>
                </template>
                <template v-else-if="skillBlock.video_content_two == true">
                  <template v-if="isMobile(true)">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                      <iframe :src="skillBlock.video_two_vimeo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="OCS Studios - Walkthrough 2"></iframe>
                    </div>
                  </template>
                  <template v-else>
                    <video class='vid' muted autoplay='1' loop='0'>
                      <source :src="skillBlock.video_two">
                    </video>
                  </template>
                </template>
              </b-col>
            </template>
            <template v-else>
              <b-col cols lg="8" sm="12">
                <template v-if="skillBlock.image_content_two == true">
                  <div class="outerSkills_image" style="height: 100%;">
                    <template v-if="$route.name == 'virtual-reality'">
                    <div class="playButton">
                      <b-button variant="danger" class="playBtnVRTwo" v-b-modal.modal-centertwov2>Play VR</b-button>
                      <b-img class="images mainImage imageTwo" :src="skillBlock.image_two"></b-img>
                    </div>
                      <div>
                        <b-modal id="modal-centertwov2" centered title="Virtual Reality" size="xl">
                          <iframe
                            class="iframemovement"
                            width="100%"
                            height="800px" 
                            style="border: none!important;"
                            src="https://wp-ocssport.ocs-sport.com/wp-content/themes/wp-bootstrap-starter/hapstead/mountpark/c3/index.htm"
                          >
                          </iframe>
                        </b-modal>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="$route.name == 'photo-real-visualisation-and-rendering'">
                        <b-img class="images mainImage photoReal" :src="skillBlock.image_two"></b-img>  
                      </template>
                      <template v-else>
                        <b-img class="images mainImage" :src="skillBlock.image_two"></b-img>  
                      </template>     
                    </template> 
                  </div>
                </template>
                <template v-else-if="skillBlock.video_content_two == true">
                  <template v-if="isMobile(true)">
                    <div style="padding:56.25% 0 0 0;position:relative; margin-bottom: 30px;">
                      <iframe :src="skillBlock.video_two_vimeo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="OCS Studios - Walkthrough 3"></iframe>
                    </div>
                  </template>
                  <template v-else>
                    <video class='vid' muted autoplay='1' loop='0'>
                      <source :src="skillBlock.video_two">
                    </video>
                  </template>
                </template>
              </b-col>
              <b-col cols lg="4" sm="12">
                <template v-if="skillBlock.image_content_three == true">
                  <div class="outerSkills_image subImageDiv">
                    <template v-if="$route.name == 'virtual-reality'">
                      <div class="playButton">
                        <b-button variant="danger" class="playBtnVRThree" v-b-modal.modal-centerthree>Play VR</b-button>
                        <b-img class="images mainImage" :src="skillBlock.image_three" v-b-modal.modal-centerthree></b-img>
                      </div>
                      <div>
                        <b-modal id="modal-centerthree" centered title="Virtual Reality" size="xl">
                          <iframe
                            class="iframemovement"
                            width="100%"
                            height="800px" 
                            style="border: none!important;"
                            src="https://wp-ocssport.ocs-sport.com/wp-content/themes/wp-bootstrap-starter/5west-alex-thompson/c6/index.htm"
                          >
                          </iframe>
                        </b-modal>
                      </div>
                    </template>
                    <template v-else>
                      <b-img class="images mainImage" :src="skillBlock.image_three"></b-img>       
                    </template> 
                  </div>
                </template>
                <template v-else-if="skillBlock.video_content_three == true">
                  <template v-if="isMobile(true)">
                    <div style="padding:56.25% 0 0 0;position:relative;margin-bottom: 30px;">
                      <iframe :src="skillBlock.video_three_vimeo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="OCS Studios - Walkthrough 3"></iframe>
                    </div>
                  </template>
                  <template v-else>
                    <video class='vid' muted autoplay='1' loop='0'>
                      <source :src="skillBlock.video_three">
                    </video>
                  </template>
                </template>
                <template v-if="skillBlock.image_content_four == true">
                  <div class="outerSkills_image">
                    <template v-if="$route.name == 'virtual-reality'">
                      <div class="playButton">
                        <b-button variant="danger" class="playBtnVRThree" v-b-modal.modal-centerfour>Play VR</b-button>
                        <b-img class="images mainImage" :src="skillBlock.image_four" v-b-modal.modal-centerfour></b-img>
                      </div>
                      <div>
                        <b-modal id="modal-centerfour" centered title="Virtual Reality" size="xl">
                          <iframe
                            class="iframemovement"
                            width="100%"
                            height="800px" 
                            style="border: none!important;"
                            src="https://wp-ocssport.ocs-sport.com/wp-content/themes/wp-bootstrap-starter/salon-suite/index.htm"
                          >
                          </iframe>
                        </b-modal>
                      </div>
                    </template>
                    <template v-else>
                      <b-img class="images mainImage" :src="skillBlock.image_four"></b-img>       
                    </template> 
                  </div>
                </template>
                <template v-else-if="skillBlock.video_content_four == true">
                  <template v-if="isMobile(true)">
                    <div style="padding:56.25% 0 0 0;position:relative;margin-bottom: 30px;">
                      <iframe :src="skillBlock.video_four_vimeo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="OCS Studios - Walkthrough 4"></iframe>
                    </div>
                  </template>
                  <template v-else>
                    <video class='vid' muted autoplay='1' loop='0'>
                      <source :src="skillBlock.video_four">
                    </video>
                  </template>
                </template>
              </b-col>
            </template>
          </b-row>
          <template v-if="skillBlock.more_images__videos == true">
            <template >
              <b-row class="imageSkills">
                <b-col class="elementBox" lg="6" sm="12" v-for="(element, index) in skillBlock.image__video" :key="index">
                  <div class="outerBox">
                    <b-img class="images mainImage" :src="element.image"></b-img>
                  </div>
                </b-col>
              </b-row>
            </template>
          </template>
        </div>
      </div>
      <div class="componentHome_block">
        <div id="skills">
          <Skills />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skills from '@/components/Skills.vue'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'
import axios from 'axios';
export default {
  name: 'Independent Skills',
  data: function() {
    return {
      acf: [],
    };
  },
  components: {
    Skills,
    Contact,
    About
  },
  computed: {
    wpPageId: function(page) {
      if (this.$route.name === "photo-real-visualisation-and-rendering") page = 50;
      else if (this.$route.name === "3d-walking") page = 51;
      else if (this.$route.name === "3d-floor-plans") page = 445;
      else if (this.$route.name === "models") page = 451;
      else if (this.$route.name === "water-colours") page = 455;
      else if (this.$route.name === "virtual-reality") page = 52;
      else if (this.$route.name === "website-design-and-development") page = 53;
      else if (this.$route.name === "mobile-apps") page = 54;
      else if (this.$route.name === "ecommerce-and-payment-solutions") page = 55;
      return page;
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/pages/" + this.wpPageId
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
  methods: {
      isMobile() {
          if( screen.width <= 500 ) {
              return true;
          }
          else {
              return false;
          }
      },
  }
}
</script>
<style scoped>
.elementBox {
  margin-bottom: 30px;
}
.outerBox {
  display: block;
  overflow: hidden;
}
.imageSkills {
  margin-top: 30px;
}
.playButton {
  position: relative;
}
button.playBtnVR {
  background-color: #7ebd63;
  border-radius: 0px;
  position: absolute;
  bottom: 50%;
  right: 47%;
  z-index: 99;
  margin: auto;
  text-align: center;
  display: block;
  width: 100px;
}
button.playBtnVR:hover {
  background-color: #efd170;
  border-color: #efd170;
}
button.playBtnVRTwo {
  background-color: #7ebd63;
  border-radius: 0px;
  position: absolute;
  bottom: 45%;
  right: 43%;
  z-index: 99;
  margin: auto;
  text-align: center;
  display: block;
  width: 100px;
}
button.playBtnVRTwo:hover {
  background-color: #efd170;
  border-color: #efd170;
}
button.playBtnVRThree {
  background-color: #7ebd63;
  border-radius: 0px;
  position: absolute;
  bottom: 40%;
  right: 35%;
  z-index: 99;
  margin: auto;
  text-align: center;
  display: block;
  width: 100px;
}
button.playBtnVRThree:hover {
  background-color: #efd170;
  border-color: #efd170;
}
.video-banner {
  width: 100%;
  background-size: 100% auto!important;
  background-repeat: no-repeat!important;
  height: 37vw;
  max-height: 800px;
  background-position: 50% 50%!important;
  overflow: hidden;
}
.vimeo-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  top: 25%;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 200vh;
  min-width: 177.77vh;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-title-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  height: 56.25%;
  width: 100%;
}
.video-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.vimeo-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
}
.outerLoop {
  overflow: hidden;
  position: relative;
  max-height: 700px;
}
.videoLoop {
}
#myVideo {
  width: 100%;
  min-height: 100%;
  margin-top: -173px;
}
::v-deep .photoReal {
  height: 467px;
}
::v-deep .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}
::v-deep .carousel-control-next-icon {
  display: inline-block;
  width: 39px;
  height: 35px;
  background: 50% / 100% 100% no-repeat;
}
::v-deep .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}
::v-deep .carousel-control-prev-icon {
  display: inline-block;
  width: 39px;
  height: 35px;
  background: 50% / 100% 100% no-repeat;
}
::v-deep .carousel-control-prev-icon {
  background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/04/Carousle-Arrow-LEFT.png');
}
::v-deep .carousel-control-next-icon {
  background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/05/Carousle-Arrow-RIGHT.png');
}
img.images.mainImage.imageTwo {
  height: 379px;
}
video.vid {
  width: 100%;
}
.outerSkills_image {
  display: block;
  overflow: hidden;
}
.Secondimages {
  height: 100%;
  width: 100%;
  transition: all .5s;
}
.Secondimages:hover, .Secondimages:focus {
  transform: scale(1.2);
}
img.Subimages {
  width: 100%;
  transition: all .5s;
}
.subImageDiv {
  margin-bottom: 25px;
}
img.Subimages:hover, img.Subimages:focus  {
   transform: scale(1.2);
}
.images {
  width: 100%;
}
img.images.mainImage {
  transition: all .5s;
}
.bottomSet {
  margin-top: 30px;
}
img.images.mainImage:hover, img.images.mainImage:focus  {
 transform: scale(1.2);
}
h4.sub_Header {
  color: #efd170;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 100;
  padding-bottom: 25px;
  margin-top: 30px;
}
p.desctiption {
  font-size: 14px;
  color: #000322;
  padding-bottom: 10px;
}
img.backArrow {
  width: 15px;
  position: relative;
  top: -2px;
}
h3.Header {
  color: #7ebd63;
  text-transform: uppercase;
  text-align: left;
  font-size: 30px;
  padding-bottom: 15px;
  font-weight: 100;
  margin-bottom: 40px;
  margin-top: 100px;
}
.backButton {
  color:#000322;
  text-align: left;
  font-size: 20px;
}
.backButton:hover {
  color:#efd170;
  text-align: left;
  font-size: 20px;
  text-decoration: none;
}
.independent_Skills_Section {
  text-align: left;
  margin-top: 100px;
}
.btn-primary {
  color: #fff;
  background-color: #000322;
  border-color: #000322;
}
.btn-primary:hover {
  color: #fff;
  background-color: #7ebd63;
  border-color: #7ebd63;
}

@media only screen and (max-width: 500px) {
  .video-banner {
    width: 100%;
    background-size: 100% auto!important;
    background-repeat: no-repeat!important;
    height: 100vw;
    max-height: 800px;
    background-position: 50% 50%!important;
    overflow: hidden;
  }
  .vimeo-wrapper {
    position: relative;
    padding-bottom: 100%;
    padding-top: 0;
    height: 0;
  }
}
@media only screen and (max-width: 990px) {
  img.images.mainImage.imageTwo {
    height: auto;
  }
  .subImageDiv  {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .independent_Skills_Section {
    text-align: left;
    margin-top: 100px;
    margin-bottom: 20px;
  }
  iframe.iframemovement {
    height: 200px;
  }
  button.playBtnVR {
    background-color: #7ebd63;
    border-radius: 0px;
    position: absolute;
    bottom: 45%;
    right: 42%;
    z-index: 99;
    margin: auto;
    text-align: center;
    display: block;
    width: 100px;
  }
  button.playBtnVRTwo {
    background-color: #7ebd63;
    border-radius: 0px;
    position: absolute;
    bottom: 45%;
    right: 41%;
    z-index: 99;
    margin: auto;
    text-align: center;
    display: block;
    width: 100px;
  }
  button.playBtnVRThree {
    background-color: #7ebd63;
    border-radius: 0px;
    position: absolute;
    bottom: 42%;
    right: 41%;
    z-index: 99;
    margin: auto;
    text-align: center;
    display: block;
    width: 100px;
  }
}
</style>
