<template>
  <div class="Skills">
    <div class="container">
        <h3 class="Header" >OCS Studios Blog</h3>
        <div v-html="content.content.rendered"></div>
        <b-row class="catSection">
            <b-col lg="3" v-for="(cat, index) in categories" :key="index" v-show="cat.id != '1'" class="catBlock">
                <b-button @click.prevent="catId(cat.id, index);" class="catBtn" variant="" :class="{ active: index === activeItem}">{{ cat.name }}</b-button>
            </b-col>
        </b-row>
        <template v-if="Clicked == 'Y'">
            <b-row>
                <b-col cols lg='4' sm='12' class="skillsBlock" v-for="(blog, index) in array" :key="index">
                    <b-link class="skillsNoUnderline" :to='"/blog/" + blog.slug'>
                        <div class="outerSkills_image">
                            <b-img class="Skills_image" :src="blog._embedded['wp:featuredmedia']['0'].source_url"></b-img>                  
                        </div>
                        <div class="innerSkills_block">
                            <h3 class="Skill_title">{{blog.title.rendered}}</h3>
                            <div class="SkillExerpt">
                                <p class="Skill_excerpt" v-html="blog.excerpt.rendered"></p>
                            </div>
                            <b-button :to='"/blog/" + blog.slug' class="find_out_more" variant="danger">Read Full Article</b-button>
                        </div>
                    </b-link>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <b-row>
                <b-col cols lg='4' sm='12' class="skillsBlock" v-for="(blog, index) in posts" :key="index">
                    <template>
                        <b-link class="skillsNoUnderline" :to='"/blog/" + blog.slug'>
                            <div class="outerSkills_image">
                            <b-img class="Skills_image" :src="blog._embedded['wp:featuredmedia']['0'].source_url"></b-img>                  
                            </div>
                            <div class="innerSkills_block">
                                <h3 class="Skill_title">{{blog.title.rendered}}</h3>
                                <div class="SkillExerpt">
                                    <p class="Skill_excerpt" v-html="blog.excerpt.rendered"></p>
                                </div>
                                <b-button :to='"/blog/" + blog.slug' class="find_out_more" variant="danger">Read Full Article</b-button>
                            </div>
                        </b-link>
                    </template>
                </b-col>
            </b-row>
        </template>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Blog',
  data: function() {
    return {
      posts: [],
      content:[],
      categories:[],
      array: [],
      Clicked: '',
      activeItem: null,
    };
  },
  methods: {
    getCat: function() {
     axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/categories"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.categories = response.data
        )); //API data wanted pulled out
    },
    catId: function(id, index) {
        this.activeItem = index;
        this.Clicked = 'Y'
        let array = []
        this.array = array 
        this.catID = id;
        this.posts.forEach((value) => {
            if (value.categories[0] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[1] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[2] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[3] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[4] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[5] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[6] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
            if (value.categories[7] == this.catID ) {
                this.isActive = !this.isActive;
                value
                let postValue = value
                array.push(postValue)
            } 
        });
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/posts?_embed"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.getCat(),
            this.posts = response.data
        )) //API data wanted pulled out
  },
  created() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/pages/437"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.content = response.data
        )) //API data wanted pulled out
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.catBlock.col-lg-3 {
    padding-bottom: 20px;
}
.catBlock.col {
    max-width: 100%;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff!important;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
.catSection {
    margin-top: 50px;
    margin-bottom: 50px;
}
.catBtn {
    background-color: #F5F5F5;
    border-color: #F5F5F5!important;
    border-radius: 0px;
    /* height: 31px; */
    line-height: 1.1;
    font-size: 15px;
    color: #010322!important;
    width: 100%;
}
.catBtn:hover {
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
.Skills {
    padding-bottom: 100px;
}
::v-deep .btn-danger {
    color: #fff;
    background-color: #efd170;
    border-color: #efd170!important;
}
.outerSkills_image {
    display: block;
    overflow: hidden;
}
a.skillsNoUnderline {
    text-decoration: none!important;
}
.SkillExerpt {
    margin-bottom: 35px;
    display: flex;
}
.Skills_image {
    width: 100%;
    transition: all .5s;
}
.Skills_image:hover, .Skills_image:focus  {
    width: 100%;
    transform: scale(1.2);
}
h3.Skill_title {
    color: #7ebd63;
    font-weight: 100;
    font-size: 20px;
    margin-bottom: 20px;
}
h3.Skill_title:hover {
    text-decoration: underline;
    font-weight: 100;
}
p.Skill_excerpt {
    color: #000322;
    font-size: 14px;
}
.skillsBlock {
    text-align: left;
    margin-bottom: 40px;
}
a.btn.find_out_more.btn-danger {
    background-color: #efd170;
    border-color: #efd170!important;
    border-radius: 0px;
    width: 163px;
    height: 31px;
    line-height: 1.1;
    font-size: 15px;
    color: #010322;
    position: absolute;
    bottom: 0;
}
a.btn.find_out_more.btn-danger:hover {
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
h3.Header {
    color: #7ebd63;
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 100;
    margin-bottom: 40px;
    margin-top: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
</style>
