<template>
  <div>
    <b-container>
        <div class="BackBtn">
          <b-button to='/blogs' class="find_out_more" variant="danger">Back to All Blog Posts</b-button>
        </div>
        <!-- <img class="postImage" :src="posts[0].acf.main_image"> -->
        <h3 class="Header">{{posts[0].title.rendered}}</h3>
        <p class="Skill_excerpt" v-html="posts[0].content.rendered"></p>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Blog Article',
  props: ["id"],
  data: function() {
    return {
      posts: [],
    };
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-studios/wp-json/wp/v2/posts?slug=" + this.id
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.posts = response.data,
            console.log("this.posts"),
            console.log(this.posts)
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
::v-deep figcaption.wp-element-caption {
  position: relative;
  top: -25px;
  z-index: 999;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
::v-deep figure.wp-block-gallery.has-nested-images.columns-default.is-cropped.wp-block-gallery-1.is-layout-flex > figure > img {
  /* height: 350px; */
}
::v-deep figure.wp-block-gallery.has-nested-images.columns-default.is-cropped.wp-block-gallery-1.is-layout-flex > figure {
  margin: 10px 10px 0rem 10px;
}
::v-deep figure.wp-block-gallery.has-nested-images.columns-default.is-cropped.wp-block-gallery-1.is-layout-flex {
  display: flex;
  /* width: 100%; */
}
::v-deep img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: auto;
}
::v-deep h2.wp-block-heading {
  color: #7ebd63;
  font-weight: 100;
  font-size: 20pt;
  margin-bottom: 30px;
  margin-top: 30px;
}
.BackBtn {
  text-align: left;
  padding-top: 50px;
}
img.postImage {
    width: 100%;
    margin-top: 50px;
}
::v-deep .btn-danger {
  color: #fff;
  background-color: #efd170;
  border-color: #efd170!important;
}
.outerSkills_image {
  display: block;
  overflow: hidden;
}
a.skillsNoUnderline {
  text-decoration: none!important;
}
.SkillExerpt {
  margin-bottom: 35px;
  display: flex;
}
.Skills_image {
  width: 100%;
  transition: all .5s;
}
.Skills_image:hover, .Skills_image:focus  {
  width: 100%;
  transform: scale(1.2);
}
h3.Skill_title {
  color: #7ebd63;
  font-weight: 100;
  font-size: 20px;
  margin-bottom: 20px;
}
h3.Skill_title:hover {
  text-decoration: underline;
  font-weight: 100;
}
p.Skill_excerpt {
  color: #000322;
  font-size: 14px;
  text-align: left;
  padding-bottom: 50px;
}
.skillsBlock {
  text-align: left;
  margin-bottom: 40px;
}
a.btn.find_out_more.btn-danger {
  background-color: #efd170;
  border-color: #efd170!important;
  border-radius: 0px;
  /* width: 163px; */
  height: 31px;
  line-height: 1.1;
  font-size: 15px;
  color: #010322;
  /* position: absolute;
  bottom: 0; */
}
a.btn.find_out_more.btn-danger:hover {
  background-color: #7ebd63;
  border-color: #7ebd63!important;
}
.Skills {
  padding-top: 200px;
  padding-bottom: 50px;
}
h3.Header {
  color: #7ebd63;
  text-transform: uppercase;
  text-align: left;
  font-size: 30px;
  padding-bottom: 15px;
  font-weight: 100;
  margin-bottom: 40px;
  margin-top: 80px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
::v-deep h3.wp-block-heading {
  color: #7ebd63;
  font-weight: 100;
  font-size: 20pt;
  margin-bottom: 30px;
  margin-top: 30px;
}
::v-deep h4.wp-block-heading {
  color: #7ebd63;
  font-weight: 100;
  font-size: 20pt;
  margin-bottom: 30px;
  margin-top: 30px;
}
</style>
